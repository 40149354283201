// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-thanks-js": () => import("./../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-single-page-js": () => import("./../../src/templates/single/Page.js" /* webpackChunkName: "component---src-templates-single-page-js" */),
  "component---src-templates-single-supplier-js": () => import("./../../src/templates/single/Supplier.js" /* webpackChunkName: "component---src-templates-single-supplier-js" */),
  "component---src-templates-suppliers-js": () => import("./../../src/templates/suppliers.js" /* webpackChunkName: "component---src-templates-suppliers-js" */)
}

